import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Breadcrumb from '../components/Breadcrumb';
import HRService from '../components/Service/HRService';
import PrototypeService from '../components/Service/Sservice/PrototypeService';
import Partner from '../components/Partner';
import ServiceSubscribe from '../components/ServiceSubscribe';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';
const ServiceData = {
    HRService: [
        {
            id: '1',
            HRtitles : '快速定位',
            HRdescription: '通过扫描问题件二维码即可快速定位所属班次/机台/责任人/生产时间等信息，进而快速定位同批次可能存在问题的产品。',
            Hicon: 'bolt',
            rclass : 'pr_70',
            iclass: 'icon_six',
        },
        {
            id: '2',
            HRtitles : '操作便利',
            HRdescription: '可快速关联产品和工序卡，后续工序通过扫码枪扫描工序卡即可快速过序。单次过序时间可在1秒内完成。',
            Hicon: 'layout-grid2',
            rclass : 'pl_50 pr_20',
            iclass: 'icon_two',
        },
        {
            id: '3',
            HRtitles : '不只是溯源',
            HRdescription: '除了溯源基本的功能，还可通过系统查看到各个工序的产品积压情况。',
            Hicon: 'fullscreen',
            rclass : 'pl_70',
            iclass: 'icon_three',
        },
    ]
}

const TraceService = () => {
    return(
        <div className="body_wrapper">
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto"/>
            <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="溯源系统" Pdescription="让每件产品可以追溯，出现质量问题时可快速准确定位问题产品，将损失降到最低。"/>
            {/*<PrototypeService settings={settings}/>*/}
            <HRService ServiceData={ServiceData}/>
            {/*<Partner pClass="partner_logo_area_two" pClasst="pt-0 mb-0"/>*/}
            {/*<ServiceSubscribe sClass="s_form_info_two"/>*/}
            <FooterTwo fClass="pt_150" FooterData={FooterData}/>
        </div>
    )
}
export default TraceService;
