import React, { Component } from 'react';
import CloudServiceItem from './CloudServiceItem';

class CloudService extends Component{
    render(){
        return(
            <section className="software_featured_area">
                <div className="container">
                    <h2 className="f_600 f_size_30 t_color3 text-center l_height40 mb_70 wow fadeInUp" data-wow-delay="0.3s">主要产品</h2>
                    <div className="row software_featured_info">
                        <CloudServiceItem icon="icon2.png" sTitle="实时物联系统" Sp="实时采集压铸机数据，可及时了解生产情况和发现生产问题，辅助生产决策，降低管理难度和成本。"
                        sbtn="了解更多" href={"/RealtimeService"}/>
                        <CloudServiceItem icon="icon3.png" sTitle="溯源系统" Sp="让每件产品可以追溯，出现质量问题时可快速准确定位问题产品，将损失降到最低。"
                        sbtn="了解更多" href={"/TraceService"}/>
                        <CloudServiceItem icon="icon1.png" sTitle="培训系统" Sp="机边滚动播放培训内容，针对不同工龄的员工侧重内容不同，降低培训成本，减少生产事故和生产质量问题。"
                        sbtn="了解更多" href={"/TrainService"}/>
                    </div>
                </div>
            </section>
        )
    }
}
export default CloudService;
