import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Breadcrumb from '../components/Breadcrumb';
import HRService from '../components/Service/HRService';
import PrototypeService from '../components/Service/Sservice/PrototypeService';
import Partner from '../components/Partner';
import ServiceSubscribe from '../components/ServiceSubscribe';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';
const ServiceData = {
    HRService: [
        {
            id: '1',
            HRtitles : '实时响应',
            HRdescription: '数据实时采集，可在系统实时查看生产情况，车间问题秒级反馈，生产问题不再只能事后问责。',
            Hicon: 'bolt',
            rclass : 'pr_70',
            iclass: 'icon_six',
        },
        {
            id: '2',
            HRtitles : '使用简单',
            HRdescription: '界面简洁易用，同时提供PC端和小程序端应用，用户可以随时随地上报或查看生产数据。',
            Hicon: 'layout-grid2',
            rclass : 'pl_50 pr_20',
            iclass: 'icon_two',
        },
        {
            id: '3',
            HRtitles : '数据准确',
            HRdescription: '采集设备支持数据重发，可允许短暂网络抖动，客户反馈准确率达99.95%。',
            Hicon: 'fullscreen',
            rclass : 'pl_70',
            iclass: 'icon_three',
        },
    ]
}

const settings = {
    title: '主要功能',
    pTitle: '',
    data: [
        {
            icon: <div className="icon s_icon_two"><i className="ti-stats-up"></i></div>,
            title: '实时统计',
            content: '可以实时看到整个车间的生产数据，如开机率，废品率，完成率等，可实时发现生产过程存在的问题。\n',
            img: require('../img/home2/report.png'),
        },
        {
            icon: <div className="icon s_icon_three"><i className="ti-stats-up"></i></div>,
            title: '多维分析',
            content: '可从不同的角度（机台/班组/员工/模具等）对数据进行分析，更深入了解生产数据，发现生产管理问题。\n',
            img: require('../img/home2/report.png'),
        },
        {
            icon: <div className="icon s_icon_five"><i className="ti-search"></i></div>,
            title: '模具管理',
            content: '对模具的整个生命周期（从录入到报废）进行管理。模具需要保养或即将报废时可提醒相关责任人。\n',
            img: require('../img/home2/file.png'),
        },
        {
            icon: <div className="icon s_icon_one"><i className="ti-check"></i></div>,
            title: '实时告警',
            content: '遇到停机时长太长、生产故障、生产进度滞后等生产问题，相关责任人可实时接收到告警信息。\n',
            img: require('../img/home2/undraw.png'),
        },
        {
            icon: <div className="icon s_icon_four"><i className="ti-stats-up"></i></div>,
            title: '故障记录',
            content: '对每一次故障记录故障原因，故障关联机台和模具，员工上机前扫机台二维码了解历史故障，减少生产问题。\n',
            img: require('../img/home2/file.png'),
        },
        {
            icon: <div className="icon s_icon_three"><i className="ti-location-arrow"></i></div>,
            title: '实时大屏',
            content: '大屏数据实时更新，包括汇总数据展示大屏和单台设备数据展示大屏，将其放在车间现场，管理人员可以方便查看各项生产指标。\n',
            img: require('../img/home2/report.png'),
        }
    ]
}
const RealtimeService = () => {
    return(
        <div className="body_wrapper">
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto"/>
            <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="实时物联系统" Pdescription="实时采集压铸机数据，可及时了解生产情况和发现生产问题，辅助生产决策，降低管理难度和成本。"/>
            <PrototypeService settings={settings}/>
            <HRService ServiceData={ServiceData}/>
            {/*<Partner pClass="partner_logo_area_two" pClasst="pt-0 mb-0"/>*/}
            {/*<ServiceSubscribe sClass="s_form_info_two"/>*/}
            <FooterTwo fClass="pt_150" FooterData={FooterData}/>
        </div>
    )
}
export default RealtimeService;
