import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Breadcrumb from '../components/Breadcrumb';
import HRService from '../components/Service/HRService';
import PrototypeService from '../components/Service/Sservice/PrototypeService';
import Partner from '../components/Partner';
import ServiceSubscribe from '../components/ServiceSubscribe';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';
const ServiceData = {
    HRService: [
        {
            id: '1',
            HRtitles : '因材施教',
            HRdescription: '针对不同工龄的员工侧重播放不同的内容，针对员工弱项增加更多相关的培训内容，培训效果更好。',
            Hicon: 'vector',
            rclass : 'pr_70',
            iclass: 'icon_one',
        },
        {
            id: '2',
            HRtitles : '灵活调整',
            HRdescription: '管理培训内容跟管理文件一样简单，针对不同的机台以及不同的产品可设置不同的培训内容，而且可自由调整培训项目的权重。',
            Hicon: 'panel',
            rclass : 'pl_50 pr_20',
            iclass: 'icon_two',
        },
        {
            id: '3',
            HRtitles : '智能播放',
            HRdescription: '操作员工交班或者模具更换时培训内容自动调整，播放过程无需人工干预。',
            Hicon: 'fullscreen',
            rclass : 'pl_70',
            iclass: 'icon_three',
        },
    ]
}

const TrainService = () => {
    return(
        <div className="body_wrapper">
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto"/>
            <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="培训系统" Pdescription="机边滚动播放培训内容，针对不同工龄的员工侧重点不一样，降低员工的培训成本，减少生产事故和生产质量问题。"/>
            {/*<PrototypeService settings={settings}/>*/}
            <HRService ServiceData={ServiceData}/>
            {/*<Partner pClass="partner_logo_area_two" pClasst="pt-0 mb-0"/>*/}
            {/*<ServiceSubscribe sClass="s_form_info_two"/>*/}
            <FooterTwo fClass="pt_150" FooterData={FooterData}/>
        </div>
    )
}
export default TrainService;
