import React, {Component} from 'react';
import Slider from 'react-slick'

class Pserviceslider extends Component{
    render(){
        const { data } = this.props
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            arrows: true,
            autoplay: true,
            autoplaySpeed: 2000,
            slidesToShow: 3,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: '100px',
            responsive: [
                {
                  breakpoint: 1250,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: '100px',
                  }
                },
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    centerMode: false,
                    centerPadding: '0px',
                  }
                },
                {
                  breakpoint: 576,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                    centerPadding: '0px',
                  }
                }
              ]
        };
        return(
            <Slider className="service_carousel" {...settings}>
                { data && data.map((item, idx) => {
                    return <div key={idx} className="service_item">
                        {item.icon}
                        <h4 className="f_600 f_size_20 l_height28 t_color2 mb_20">{item.title}</h4>
                        <p>{item.content}</p>
                        <img className="float-right" src={item.img} alt=""/>
                    </div>
                })}
            </Slider>
        )
    }
}
export default Pserviceslider;
