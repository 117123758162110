import React, {Component} from 'react';
import Sectitle from '../../Title/Sectitle'
import Pserviceslider from './Pserviceslider';

class Sservice extends Component{
    render(){
        const {settings} = this.props
        return(
            <section className="prototype_service_area_two">
                <div className="container custom_container">
                    <Sectitle Title={settings.title} TitleP={settings.titleP}
                    tClass="t_color3" sClass="sec_title text-center mb_70"/>
                    <Pserviceslider data={settings.data}/>
                </div>
            </section>
        )
    }
}
export default Sservice;
